<template>
  <v-container fluid :class="{ 'pa-0': no_padding }">
    <template v-if="data || loading">
      <v-toolbar v-if="!no_label" dense dark color="secondary">
        <v-toolbar-title v-if="label">{{ label }}</v-toolbar-title>
        <v-spacer />
        <export_to_pdf_btn
          :name="export_name"
          :labels="labels"
          :data="exportedData"
          :disabled="loading"
          auto_table
        ></export_to_pdf_btn>
        <export_to_csv_btn
          :name="export_name"
          :labels="labels"
          :data="exportedData"
          :disabled="loading"
        ></export_to_csv_btn>
        <Global_refresh_button
          @click="$emit('update')"
          :loading="loading"
          :time="time"
        />
      </v-toolbar>
      <v-card
        color="grey lighten-4"
        flat
        elevation="0.5"
        rounded
        width="100%"
        transition="scale-transition"
        tile
      >
        <loading_overlay v-model="loading" />
        <custom-table
          @selected="selectionChanged"
          :columns="columns"
          :data="data || []"
        ></custom-table>
      </v-card>
    </template>
    <no_data_to_show v-else></no_data_to_show>
  </v-container>
</template>

<script>
import loading_overlay from "@/components/loading_overlay";
export default {
  name: "grid_v_card",
  components: {
    Global_refresh_button: () => import("@/components/global_refresh_button"),
    loading_overlay,
    no_data_to_show: () => import("@/components/no_data_to_show"),
    export_to_csv_btn: () => import("@/components/export_to_csv_btn"),
    export_to_pdf_btn: () => import("@/components/export_to_pdf_btn"),
    CustomTable: () => import("@/components/data_grid")
  },
  data() {
    return {
      isTableOpened: true,
      selectedIndexes: []
    };
  },
  methods: {
    selectionChanged(data) {
      this.selectedIndexes = data;
    }
  },
  computed: {
    exportedData() {
      if (this.selectedIndexes.length > 0) {
        return this.selectedIndexes;
      } else {
        return this.data;
      }
    },
    labels() {
      if (this.columns) {
        let labels = [];
        this.columns.forEach(column => {
          if (
            typeof column["exportable"] !== typeof undefined &&
            column["exportable"] === false
          )
            return true;
          labels.push({
            field: column["field"],
            headerName: column["headerName"]
          });
        });
        return labels;
      }
      return [];
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    no_label: {
      type: Boolean,
      default: false
    },
    export_name: {
      type: String,
      default: ""
    },
    no_padding: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    time: {
      type: [String, Number],
      default: null
    }
  }
};
</script>

<style scoped></style>
